import { createRouter, createWebHistory } from 'vue-router';
import DefaultPages from '../layouts/default';
import LoginPages from '../layouts/login';
// import RegisterPages from "../layouts/register";
import NotFound404 from '../layouts/404';

const routes = [
  {
    path: '/',
    component: DefaultPages,
    children: [
      {
        path: '',
        name: 'home',
        component: function () {
          return import('../pages/Index.vue');
        },
        meta: {
          auth: true,
        },
      },
      // Blok testlar
      {
        path: '/blok_category',
        name: 'blok_category',
        component: function () {
          return import('../pages/blok_test/category.vue');
        },
        meta: {
          auth: true,
        },
      },
      // ===============
      // Imtihonlar
      {
        path: '/exams',
        name: 'exams',
        component: function () {
          return import('../pages/Exams.vue');
        },
        meta: {
          auth: true,
        },
      },
      // ===============
      {
        path: '/blok_subject/:id',
        name: 'blok_subject.show',
        component: function () {
          return import('../pages/blok_test/subject.vue');
        },
        meta: {
          auth: true,
        },
      },
      {
        path: '/blok_testlar/:id',
        name: 'blok_testlar.show',
        component: function () {
          return import('../pages/blok_test/testlar.vue');
        },
        meta: {
          auth: true,
        },
      },
      {
        path: '/blok_test_add/:id',
        name: 'blok_test_add.show',
        component: function () {
          return import('../pages/blok_test/test_add.vue');
        },
        meta: {
          auth: true,
        },
      },
      {
        path: '/blok_test_edit/:id',
        name: 'blok_test_edit.show',
        component: function () {
          return import('../pages/blok_test/test_edit.vue');
        },
        meta: {
          auth: true,
        },
      },
      // Blok testlar end
      // Ixtisos check ball
      {
        path: '/ixtisos_check_ball/viloyat',
        name: '/ixtisos_check_ball/viloyat',
        component: function () {
          return import('../pages/ixtisos_check_ball/Viloyat.vue');
        },
        meta: {
          auth: true,
        },
      },
      {
        path: '/ixtisos_check_ball/tuman/:id',
        name: '/ixtisos_check_ball/tuman.show',
        component: function () {
          return import('../pages/ixtisos_check_ball/Tuman.vue');
        },
        meta: {
          auth: true,
        },
      },
      {
        path: '/ixtisos_check_ball/maktab/:id',
        name: '/ixtisos_check_ball/maktab.show',
        component: function () {
          return import('../pages/ixtisos_check_ball/Maktab.vue');
        },
        meta: {
          auth: true,
        },
      },
      {
        path: '/ixtisos_check_ball/yunalish/:id',
        name: '/ixtisos_check_ball/yunalish.show',
        component: function () {
          return import('../pages/ixtisos_check_ball/Yunalish.vue');
        },
        meta: {
          auth: true,
        },
      },
      // Ixtisos check ball end
      {
        path: '/team',
        name: 'team',
        component: function () {
          return import('../pages/Team.vue');
        },
        meta: {
          auth: true,
        },
      },
      {
        path: '/ads',
        name: 'ads',
        component: function () {
          return import('../pages/Ads.vue');
        },
        meta: {
          auth: true,
        },
      },
      {
        path: '/comment',
        name: 'comment',
        component: function () {
          return import('../pages/Comment.vue');
        },
        meta: {
          auth: true,
        },
      },
      {
        path: '/linkcreate',
        name: 'linkcreate',
        component: function () {
          return import('../pages/link/Index.vue');
        },
        meta: {
          auth: true,
        },
      },
      {
        path: '/winners/:id',
        name: 'winners.show',
        component: function () {
          return import('../pages/user/Winners.vue');
        },
        meta: {
          auth: true,
        },
      },
      {
        path: '/addhamkor/:id',
        name: 'addhamkor.show',
        component: function () {
          return import('../pages/link/AddHamkor.vue');
        },
        meta: {
          auth: true,
        },
      },
      {
        path: '/profilekonkursresult/:id',
        name: 'profilekonkursresult.show',
        component: function () {
          return import('../pages/link/ProfileKonkursResult.vue');
        },
        meta: {
          auth: true,
        },
      },
      {
        path: '/hamkorforadmin/:id',
        name: 'hamkorforadmin.show',
        component: function () {
          return import('../pages/link/HamkorForAdmin.vue');
        },
        meta: {
          auth: true,
        },
      },
      {
        path: '/linkprofile',
        name: 'linkprofile',
        component: function () {
          return import('../pages/link/Profile.vue');
        },
        meta: {
          auth: true,
        },
      },
      {
        path: '/arxivlink',
        name: 'arxivlink',
        component: function () {
          return import('../pages/link/ArxivLink.vue');
        },
        meta: {
          auth: true,
        },
      },
      {
        path: '/pays',
        name: 'pays',
        component: function () {
          return import('../pages/Pays.vue');
        },
        meta: {
          auth: true,
        },
      },
      {
        path: '/pupils',
        name: 'pupils',
        component: function () {
          return import('../pages/user/Pupils.vue');
        },
        meta: {
          auth: true,
        },
      },
      {
        path: '/testlar',
        name: 'testlar',
        component: function () {
          return import('../pages/Testlar.vue');
        },
        meta: {
          auth: true,
        },
      },
      {
        path: '/news',
        name: 'news',
        component: function () {
          return import('../pages/News.vue');
        },
        meta: {
          auth: true,
        },
      },
      {
        path: '/testresult/:id',
        name: 'testresult.show',
        component: function () {
          return import('../pages/user/UserTestResult.vue');
        },
        meta: {
          auth: true,
        },
        props: true,
      },
      {
        path: '/subjects/:id',
        name: 'subject.show',
        component: function () {
          return import('../pages/test/Subjects.vue');
        },
        meta: {
          auth: true,
        },
        props: true,
      },
      {
        path: '/theme/:id',
        name: 'theme.show',
        component: function () {
          return import('../pages/test/Theme.vue');
        },
        meta: {
          auth: true,
        },
        props: true,
      },
      {
        path: '/tournament/:id',
        name: 'tournament.show',
        component: function () {
          return import('../pages/test/tournament/Tournament.vue');
        },
        meta: {
          auth: true,
        },
        props: true,
      },
      {
        path: '/winnertitle',
        name: 'winnertitle.show',
        component: function () {
          return import('../pages/user/Winners-Title.vue');
        },
        meta: {
          auth: true,
        },
        props: true,
      },
      {
        path: '/tournamentadd/:id',
        name: 'tournamentadd.show',
        component: function () {
          return import('../pages/test/tournament/TournamentAdd.vue');
        },
        meta: {
          auth: true,
        },
        props: true,
      },
      {
        path: '/tournamentput/:id',
        name: 'tournamentput.show',
        component: function () {
          return import('../pages/test/tournament/TournamentPut.vue');
        },
        meta: {
          auth: true,
        },
        props: true,
      },
      {
        path: '/tournamenttests/:id',
        name: 'tournamenttests.show',
        component: function () {
          return import('../pages/test/tournament/Tests.vue');
        },
        meta: {
          auth: true,
        },
        props: true,
      },
      {
        path: '/tournamenttestadd/:id',
        name: 'tournamenttestadd.show',
        component: function () {
          return import('../pages/test/tournament/AddTests.vue');
        },
        meta: {
          auth: true,
        },
        props: true,
      },
      {
        path: '/tournamenttestput/:id',
        name: 'tournamenttestput.show',
        component: function () {
          return import('../pages/test/tournament/PutTest.vue');
        },
        meta: {
          auth: true,
        },
        props: true,
      },
      {
        path: '/tests/:id',
        name: 'test.show',
        component: function () {
          return import('../pages/test/Testlar.vue');
        },
        meta: {
          auth: true,
        },
        props: true,
      },
      {
        path: '/testadd/:id',
        name: 'testadd.show',
        component: function () {
          return import('../pages/test/Testadd.vue');
        },
        meta: {
          auth: true,
        },
        props: true,
      },
      {
        path: '/konkursresult',
        name: 'konkursresult.show',
        component: function () {
          return import('../pages/user/Konkurs.vue');
        },
        meta: {
          auth: true,
        },
        props: true,
      },
      {
        path: '/konkursresultid/:id',
        name: 'konkursresultid.show',
        component: function () {
          return import('../pages/user/ResultKonkurs.vue');
        },
        meta: {
          auth: true,
        },
        props: true,
      },
      {
        path: '/testput/:id',
        name: 'testput.show',
        component: function () {
          return import('../pages/test/Testput.vue');
        },
        meta: {
          auth: true,
        },
        props: true,
      },
    ],
  },
  // login layout/page
  {
    path: '/login',
    name: 'login',
    component: LoginPages,
  },
  // 404 layout/page
  {
    path: '/404',
    name: '404',
    component: NotFound404,
  },

  {
    path: '/:catchAll(.*)',
    redirect: '/404',
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const requireAuth = to.meta.auth;
  const token = localStorage.getItem('token');

  if (requireAuth && token) {
    if (to.name == 'login') {
      next({ name: 'home' });
      return;
    }

    next();
    return;
  }

  if (token) {
    if (to.name == 'login') {
      next({ name: 'home' });
      return;
    }
  } else {
    if (to.name != 'login') {
      next({ name: 'login' });
      return;
    }
  }
  next();
  // if (to.name !== 'Login' && !isAuthenticated) next({ name: 'Login' })
  // else next()
});

export default router;
